import { merge, omit } from 'lodash'
import {
  applyMiddleware,
  compose,
  createStore,
  combineReducers,
  StoreEnhancer,
  Middleware,
  Reducer,
  Action,
  AnyAction,
  ReducersMapObject,
} from 'redux'

import { EnhancedStore, InitialState, Middlewares, NestedReducersMapObject } from './types'

const configureStore = <S = any, A extends Action = AnyAction, M extends Middlewares<S> = Middlewares<S>>(
  reducer: NestedReducersMapObject<S, A>,
  middlewares: Middlewares<S> = [],
  initialState: InitialState<S> = {} as InitialState<S>
): EnhancedStore<S, A, M> => {
  function extensionComposeStub(enhancer: StoreEnhancer): StoreEnhancer {
    return enhancer
  }

  const isDev = (CURRENT_ENV || 'dev') === 'dev'

  const composeEnhancers =
    isDev && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : extensionComposeStub

  let allDynamicMiddlewares: Middlewares<S> = []

  const enhancers: Middleware<S> = store => next => action => {
    const chain = allDynamicMiddlewares.map(middleware => middleware(store))

    return compose<Middleware<S>>(...chain)(next)(action)
  }

  let asyncReducers = reducer

  const createReducer = <SubS extends NestedReducersMapObject<SubS, A>>(
    reducerObject: NestedReducersMapObject<SubS, A>
  ): Reducer<SubS, AnyAction> => {
    const keys = Object.keys(reducerObject) as Array<keyof SubS>

    return combineReducers<SubS>(
      keys.reduce(
        (reducer, reducerKey) => {
          if (typeof reducerObject[reducerKey] === 'function') {
            const subReducer = reducerObject[reducerKey] as Reducer<SubS[keyof SubS], A>
            reducer[reducerKey] = subReducer
          } else if (typeof reducerObject[reducerKey] === 'object') {
            const subObject = reducerObject[reducerKey] as NestedReducersMapObject<SubS[keyof SubS], A>
            reducer[reducerKey] = createReducer<SubS[keyof SubS]>(subObject)
          }
          return reducer
        },
        {} as ReducersMapObject<SubS, A>
      )
    )
  }

  const rootReducer = createReducer<S>(asyncReducers)
  const composedEnhancer = composeEnhancers(applyMiddleware(enhancers))
  const store = createStore(rootReducer, initialState, composedEnhancer) as EnhancedStore<S, A, M>

  store.injectReducer = (reducer: Record<string, Reducer>) => {
    asyncReducers = merge({}, asyncReducers, reducer)
    store.replaceReducer(createReducer(asyncReducers))
    return store
  }

  store.removeReducer = (reducerKey: string) => {
    const newReducer = omit(asyncReducers, reducerKey) as NestedReducersMapObject<S, A>
    store.replaceReducer(createReducer(newReducer))
    return store
  }

  store.addMiddleware = (...middlewares: Middleware[]) => {
    allDynamicMiddlewares = [...allDynamicMiddlewares, ...middlewares]
  }

  store.removeMiddleware = (middleware: Middleware) => {
    const index = allDynamicMiddlewares.findIndex(d => d === middleware)

    if (index === -1) {
      console.error('Middleware does not exist!', middleware)
      return
    }

    allDynamicMiddlewares = allDynamicMiddlewares.filter((_, mdwIndex) => mdwIndex !== index)
  }

  store.addMiddleware(...middlewares)

  return store
}

export default configureStore
