import { Answer, AnswerType, EntityType, FontSizeView } from '@packages/types'
import { AnyAction } from 'redux'

import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

const defaultFontSizeView = { type: AnswerType.FontSize, size: '30px' }

const createFontSizeAnswer = (state: CustomizationState, { payload }: AnyAction) => {
  const views = state.customizerProduct.views
  const question = state.questions[payload.questionId]
  const answerToCopy = state.answers[question.selectedAnswer ?? question.answers[0]] as Answer | undefined
  const viewsToCopy = answerToCopy
    ? (answerToCopy.views as FontSizeView[])
    : Array.from(Array(views)).map(() => defaultFontSizeView)

  const answer = {
    id: payload.answerId,
    entityType: EntityType.Answer,
    type: AnswerType.FontSize,
    name: '',
    isPersonalisation: true,
    views: viewsToCopy.map(view => ({
      ...view,
      size: `${Math.round(Number(view.size.replace('px', '')) * payload.scale)}px`,
    })),
  }

  const newState = {
    ...state,
    questions: {
      ...state.questions,
      [payload.questionId]: { ...question, selectedAnswer: payload.answerId },
    },
    answers: {
      ...state.answers,
      [payload.answerId]: answer,
    },
  }

  return applyLinkedQuestions(applyRules(newState))
}

export default createFontSizeAnswer
