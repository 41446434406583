"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Union {
    constructor(collectionId, definitions, key) {
        this.collectionId = collectionId;
        this.definitions = definitions;
        this.key = key;
    }
    getDefinition(entity) {
        return this.definitions.find(definition => definition.value === entity[this.key]);
    }
    getSchema(entity) {
        return this.getDefinition(entity)?.schema;
    }
    getCollectionId(entity) {
        if (!entity)
            return;
        return this.getSchema(entity).getCollectionId();
    }
    fold(entity, db, run) {
        if (!entity)
            return;
        return this.getSchema(entity).fold(entity, db, run);
    }
    shouldClear(...args) {
        return this.definitions.reduce((changed, { schema }) => {
            return changed || schema.shouldClear(...args);
        }, false);
    }
    unfold(id, db, run, options) {
        const definition = this.definitions.find(({ schema }) => db.getItem(schema.getCollectionId(), id) != null);
        return definition?.schema.unfold(id, db, run, options);
    }
}
exports.default = Union;
