"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyRounding = exports.applyMarketPriceAjustement = exports.applyExchangeRate = exports.applyTaxes = void 0;
const fp_1 = require("lodash/fp");
exports.applyTaxes = (0, fp_1.curry)((options, price) => {
    return options.applyTaxes && options.taxes ? price * ((100 + Number(options.taxes)) / 100) : price;
});
exports.applyExchangeRate = (0, fp_1.curry)((options, price) => {
    return options.rate ? price * Number(options.rate) : price;
});
exports.applyMarketPriceAjustement = (0, fp_1.curry)((options, price) => {
    if (!options.priceAdjustment)
        return price;
    return (options.priceAdjustment / 100 + 1) * price;
});
exports.applyRounding = (0, fp_1.curry)((options, price) => {
    if (!options.applyRounding || !options.rounding || price === 0 || options.currency === options.defaultCurrency) {
        return price;
    }
    let integerFactor = 1;
    if (options.rounding <= 1) {
        integerFactor = 100;
    }
    else if (String(price).includes('.')) {
        const numberOfDecimals = String(price).split('.')[1].length;
        integerFactor = Number(`1${'0'.repeat(numberOfDecimals)}`);
    }
    const adjustedRounding = Math.round(options.rounding * integerFactor);
    const adjustedPrice = Math.round(price * integerFactor);
    const numberOfDigits = String(adjustedRounding).length;
    const priceRoundingPart = Number(String(adjustedPrice).slice(-numberOfDigits));
    let roundingGap;
    if ([10, 100, 1000].includes(adjustedRounding)) {
        const rest = priceRoundingPart % adjustedRounding;
        roundingGap = rest === 0 ? 0 : adjustedRounding - rest;
    }
    else {
        roundingGap = adjustedRounding - priceRoundingPart;
    }
    if (roundingGap === 0)
        return adjustedPrice / integerFactor;
    if (roundingGap > 0)
        return (adjustedPrice + roundingGap) / integerFactor;
    return (adjustedPrice + (Number(`1${'0'.repeat(numberOfDigits)}`) + roundingGap)) / integerFactor;
});
const applyModifiers = (value, options = {}) => (0, fp_1.pipe)((0, exports.applyTaxes)(options), (0, exports.applyMarketPriceAjustement)(options), (0, exports.applyExchangeRate)(options), (0, exports.applyRounding)(options))(value);
exports.default = applyModifiers;
