import React from 'react'
import { useSelector } from 'react-redux'

import * as translationsSelectors from 'customizer/translations/selectors'

const placeholdersRegex = /(\{\{[a-zA-Z]+\}\})/g

export interface TranslationProps {
  field: string
  className?: string
  style?: { [key: string]: string }
  onClick?: (e: React.MouseEvent) => void
  values?: { [key: string]: string | number }
}

interface TranslationComponentProps extends TranslationProps {
  translations: { [key: string]: { text?: string; from?: string } }
}

export const TranslationComponent = ({
  field,
  values,
  className,
  translations,
  style,
  onClick,
}: TranslationComponentProps) => {
  if (!translations[field]) return <span />

  let text = translations[field].text?.trim() || translations[field].from

  if (text == null) return <span />

  const placeholders = text.match(placeholdersRegex)

  if (placeholders && values != null)
    text = placeholders.reduce((text, placeholder) => {
      const field = placeholder.replace('{{', '').replace('}}', '')

      return text.replace(placeholder, `${values[field]}`)
    }, text)

  return (
    <span style={style} className={className} onClick={onClick}>
      {text}
    </span>
  )
}

const Translation = ({ field, className, style, onClick, values }: TranslationProps) => {
  const translations = useSelector(translationsSelectors.themeTranslationSelector)

  return (
    <TranslationComponent
      style={style}
      field={field}
      className={className}
      translations={translations}
      onClick={onClick}
      values={values}
    />
  )
}

export default Translation
