import { HtmlSanitizer } from '@packages/sanitizer'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'

import './SanitizedHtml.scss'

export interface SanitizedHtmlProps extends React.HTMLProps<HTMLDivElement> {
  className?: string
  dirtyHtml?: string
  tagsStyle?: Record<string, string>
}

const SanitizedHtml = ({ dirtyHtml = '', className, tagsStyle = {}, ...props }: SanitizedHtmlProps) => {
  const htmlContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    Object.keys(tagsStyle).forEach(tag => {
      htmlContainerRef.current?.querySelectorAll<HTMLElement>(tag).forEach(element => {
        element.style.cssText = tagsStyle[tag]
      })
    })
  }, [dirtyHtml, tagsStyle])

  useEffect(() => {
    htmlContainerRef.current?.querySelectorAll<HTMLLinkElement>('a').forEach(element => {
      element.onclick = e => e.stopPropagation()
    })
  }, [dirtyHtml])

  return (
    <div
      ref={htmlContainerRef}
      dangerouslySetInnerHTML={{ __html: HtmlSanitizer.sanitize(dirtyHtml) }}
      className={classNames('sanitized-html', className, {
        'sanitized-html__html': HtmlSanitizer.validateType(dirtyHtml),
      })}
      {...props}
    />
  )
}

export default SanitizedHtml
