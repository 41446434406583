import Konva from 'konva'

import type { Node } from './../types/node'

interface ViewContainerConfig extends Konva.ContainerConfig {
  view: number
}

export default class ViewContainer extends Konva.Group {
  public isViewContainer: boolean
  public viewIndex: number
  private nodeCache: Record<string, Node>

  constructor(config: ViewContainerConfig) {
    super(config)

    this.isViewContainer = true
    this.viewIndex = config.view
    this.id(`view-${config.view}`)
    this.zIndex(config.view)
    this.nodeCache = {}
  }

  public getViewContainer = () => {
    return this as ViewContainer
  }

  public cacheNode(node: Node) {
    this.nodeCache[node.id] = node
  }

  public getNodeFromCache(id: string) {
    return this.nodeCache[id]
  }

  public removeNodeFromCache(id: string) {
    delete this.nodeCache[id]
  }

  public clearNodeCache() {
    this.nodeCache = {}
  }
}
