import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

export type SetQuestionDefaultConfigurationAction = {
  payload: string
}

export default (
  state: CustomizationState,
  { payload: questionId }: SetQuestionDefaultConfigurationAction
): CustomizationState => {
  const question = state.questions[questionId]
  const defaultConfiguration = state.customizerProduct.defaultConfiguration[questionId]
  const key = question.isMultiAnswer ? 'selectedAnswers' : 'selectedAnswer'

  const nextState = {
    ...state,
    questions: {
      ...state.questions,
      [questionId]: { ...question, [key]: defaultConfiguration },
    },
  }

  return applyLinkedQuestions(applyRules(nextState))
}
