import { Asset, FontAssets, FontType } from '@packages/types'

import getFontAssetsFromAsset from 'utils/getFontAssetsFromAsset'

import CustomFontLoader from './CustomFontLoader'
import GoogleFontLoader from './GoogleFontLoader'

class FontService {
  loadFont({
    fontFamily,
    type = FontType.Custom,
    asset,
    preview,
  }: {
    fontFamily: string
    type?: FontType
    asset?: FontAssets | Asset
    preview?: string
  }) {
    switch (type) {
      case FontType.Google:
        return GoogleFontLoader.loadFont(fontFamily, preview)
      default:
        return CustomFontLoader.loadFont(fontFamily, getFontAssetsFromAsset(asset))
    }
  }

  async generateEncodedFontCss({
    fontFamily,
    type,
    asset,
    preview,
  }: {
    fontFamily: string
    type?: FontType
    asset?: FontAssets | Asset
    preview?: string
  }) {
    switch (type) {
      case FontType.Google:
        return GoogleFontLoader.generateEncodedFontCss(fontFamily, preview)
      default:
        return CustomFontLoader.generateEncodedFontCss(fontFamily, getFontAssetsFromAsset(asset))
    }
  }
}

export default new FontService()
