import { money } from '@packages/money'
import React from 'react'

interface PriceProps {
  currency: string
  amount: string | number
  lang?: string
  country?: string
  className?: string
  style?: React.CSSProperties
  includeSign?: boolean
}

const Price = ({ currency, amount, lang, country, className, style, includeSign }: PriceProps) => {
  return (
    <div className={className} style={style}>
      {money(amount).toFormattedString({
        currencyCode: currency,
        languageCode: lang,
        countryCode: country,
        includeSign,
      })}
    </div>
  )
}

export default Price
