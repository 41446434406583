import * as normalize from '@packages/normalizer'
import { mapValues } from 'lodash'
import { AnyAction } from 'redux'

import applyDesign from './applyDesign'
import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import applyTranslations from './applyTranslations'
import type { CustomizationState } from './reducer'

const { Normalizer } = normalize.customizerProduct

interface ActionWithPayload extends AnyAction {
  payload: any
}

const startDesignCustomization = (_state: CustomizationState, action: AnyAction) => {
  const { customizerProduct, productName, productId } = action.payload

  const normalized = {
    rules: {},
    questions: {},
    parts: {},
    groups: {},
    answers: {},
    printAreas: {},
    ...Normalizer.run(customizerProduct, 'customizerProducts'),
  }

  const nextState = {
    isReady: true,
    isCustomizingDesign: true,
    isSubmitEnabled: true,
    productName,
    productId,
    answers: normalized.answers,
    groups: normalized.groups,
    questions: mapValues(normalized.questions, question => {
      question.restrictions = []
      question.hadInteraction = false
      question.hasFilteredWord = false
      question.hasRequiredError = false
      return question
    }),
    parts: normalized.parts,
    rules: normalized.rules,
    customizerProduct: normalized.customizerProducts[customizerProduct.id],
    printAreas: normalized.printAreas,
  }

  return applyLinkedQuestions(
    applyRules(applyDesign(applyTranslations(nextState, action as ActionWithPayload), action as ActionWithPayload))
  )
}

export default startDesignCustomization
