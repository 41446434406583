const downloadContent = (
  content: string | Blob,
  contentType:
    | 'application/json'
    | 'text/csv'
    | 'application/octet-stream'
    | 'image/png'
    | 'application/zip'
    | 'application/pdf',
  filename: string
) => {
  const link = document.createElement('a')

  if (typeof content === 'string') {
    const url = content.startsWith('data:')
      ? content
      : `data:${contentType};charset=utf-8,${encodeURIComponent(content)}`
    link.setAttribute('href', url)
  } else {
    link.href = URL.createObjectURL(content)
  }
  link.download = filename
  if (document.createEvent) {
    const event = document.createEvent('MouseEvents')
    event.initEvent('click', true, true)
    link.dispatchEvent(event)
  } else {
    link.click()
  }
}

export default downloadContent
