"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productSchema = exports.childrenSchema = exports.partDrawingPropertySchema = exports.printAreaSchema = exports.answerSchema = exports.questionSchema = exports.groupSchema = exports.partSchema = exports.customizerProductSchema = void 0;
const normalize_1 = require("../normalize");
exports.customizerProductSchema = new normalize_1.Schema('customizerProducts', {
    tree: normalize_1.Relation.oneToOne('groups'),
    rules: normalize_1.Relation.manyToMany('rules'),
    assets: normalize_1.Relation.manyToMany('assets'),
    parts: normalize_1.Relation.manyToMany('parts'),
    questions: normalize_1.Relation.manyToMany('questions'),
    printAreas: normalize_1.Relation.manyToMany('printAreas'),
});
exports.partSchema = new normalize_1.Schema('parts', {
    image: normalize_1.Relation.oneToOne('partDrawingProperty'),
    material: normalize_1.Relation.oneToOne('partDrawingProperty'),
    text: normalize_1.Relation.oneToOne('partDrawingProperty'),
    color: normalize_1.Relation.oneToOne('partDrawingProperty'),
    outline: normalize_1.Relation.oneToOne('partDrawingProperty'),
    font: normalize_1.Relation.oneToOne('partDrawingProperty'),
    fontSize: normalize_1.Relation.oneToOne('partDrawingProperty'),
    position: normalize_1.Relation.oneToOne('partDrawingProperty'),
    logo: normalize_1.Relation.oneToOne('partDrawingProperty'),
    group: normalize_1.Relation.oneToOne('partDrawingProperty'),
    printArea: normalize_1.Relation.oneToOne('printAreas'),
});
exports.groupSchema = new normalize_1.Schema('groups', {
    children: normalize_1.Relation.manyToMany('children'),
});
exports.questionSchema = new normalize_1.Schema('questions', {
    answers: normalize_1.Relation.manyToMany('answers'),
    selectedAnswer: normalize_1.Relation.oneToOne('answers'),
    selectedAnswers: normalize_1.Relation.manyToMany('answers'),
});
exports.answerSchema = new normalize_1.Schema('answers', {});
exports.printAreaSchema = new normalize_1.Schema('printAreas', {});
exports.partDrawingPropertySchema = new normalize_1.Union('partDrawingProperty', [
    {
        value: 'question',
        schema: exports.questionSchema,
    },
    {
        value: 'answer',
        schema: exports.answerSchema,
    },
], 'entityType');
exports.childrenSchema = new normalize_1.Union('children', [
    {
        value: 'question',
        schema: exports.questionSchema,
    },
    {
        value: 'group',
        schema: exports.groupSchema,
    },
], 'entityType');
exports.productSchema = new normalize_1.Schema('products', {
    live: normalize_1.Relation.oneToOne('customizerProducts'),
    draftTwoD: normalize_1.Relation.oneToOne('customizerProducts'),
    customizer: normalize_1.Relation.manyToMany('customizerProducts'),
});
