import { RuleThenType } from '@packages/types'

import type { CustomizationState } from './reducer'

const applyLinkedQuestions = (state: CustomizationState): CustomizationState => {
  let nextState = state

  for (const question of Object.values(state.questions)) {
    const hasDisableRestriction = !!Object.values(question.restrictions || {}).find(
      restriction => restriction.type === RuleThenType.DisableQuestion
    )

    if (question.linkedQuestionId) {
      nextState = {
        ...nextState,
        questions: {
          ...nextState.questions,
          [question.id]: {
            ...question,
            selectedAnswer: hasDisableRestriction ? null : state.questions[question.linkedQuestionId].selectedAnswer,
          },
        },
      }
    }
  }

  return nextState
}

export default applyLinkedQuestions
