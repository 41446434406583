import { FontAssets } from '@packages/types'
import { isEqual, isNil, omitBy } from 'lodash'

export type CachedFont = {
  srcs: FontAssets
  base64?: string
  promise?: Promise<void | FontFace>
}

class CustomFontCache {
  fonts: Record<string, CachedFont> = {}

  addFontToCache(font: string, assets: FontAssets) {
    this.fonts[font] = { srcs: assets }
  }

  verifyIfFontIsCached(font: string, assets: FontAssets) {
    return (
      this.fonts[font]?.promise !== undefined && isEqual(omitBy(this.fonts[font]?.srcs, isNil), omitBy(assets, isNil))
    )
  }

  updateFont(font: string, propsToUpdate: Partial<CachedFont>) {
    this.fonts[font] = { ...this.fonts[font], ...propsToUpdate }
  }
}

export default new CustomFontCache()
