import { AnyAction } from 'redux'

import { AppDispatch, RootState } from 'customizer/store'

import { updateRowCustomization } from './actions'
import { bulkOrderRowCustomizationSelector } from './selectors'

type BulkOrderAnyAction = AnyAction | ((dispatch: AppDispatch, getState: () => RootState) => unknown)

const getBulkOrderRowDispatch = (dispatch: AppDispatch) => {
  const getRowState = (getState: () => RootState, rowIndex: number) => () => {
    const state = getState()
    const result = bulkOrderRowCustomizationSelector(state.customization, state.bulkOrder.rows[rowIndex])

    return { ...state, customization: result }
  }

  const bulkRowDispatch = (rowIndex: number, action: BulkOrderAnyAction): unknown => {
    return dispatch((_dispatch, innerGetState) => {
      if (typeof action === 'function') {
        return action(
          ((dispatch: AppDispatch) => bulkRowDispatch(rowIndex, dispatch)) as AppDispatch,
          getRowState(innerGetState, rowIndex)
        )
      }

      return dispatch(updateRowCustomization(rowIndex, action))
    })
  }

  const bulkRowDispatchWrapper = (rowIndex: number, action: BulkOrderAnyAction) => {
    return dispatch((_dispatch, innerGetState) => {
      if (rowIndex === innerGetState().bulkOrder.previewedRowIndex) dispatch(action)

      return bulkRowDispatch(rowIndex, action)
    })
  }

  return bulkRowDispatchWrapper
}

export default getBulkOrderRowDispatch
