import { Answer, CustomizableQuestion, CustomizerProduct, StartingPoint } from '@packages/types'
import { assign } from 'lodash'

import type { CustomizationState } from './reducer'

const isAnswerValid = (question: CustomizableQuestion, answer?: Answer | null) => {
  return answer && question.answers.includes(answer.id) && !answer.archived
}

interface ApplySingleAnswerStartingPointParams {
  state: CustomizationState
  question: CustomizableQuestion
  config: Record<string, string | string[] | null>
}

export const applySingleAnswerStartingPoint = ({ state, question, config }: ApplySingleAnswerStartingPointParams) => {
  const answerId = config[question.id] as string | undefined

  let selectedAnswer

  if (!!answerId && isAnswerValid(question, state.answers[answerId])) {
    selectedAnswer = answerId
  }

  return { ...question, selectedAnswer }
}

interface ApplyMultiAnswerStartingPointParams {
  state: CustomizationState
  question: CustomizableQuestion
  config: Record<string, string | string[] | null>
}

export const applyMultiAnswerStartingPoint = ({ state, question, config }: ApplyMultiAnswerStartingPointParams) => {
  let answerIds = config[question.id]

  if (typeof answerIds === 'string' || !answerIds) answerIds = []

  const selectedAnswers = answerIds.filter(answerId => isAnswerValid(question, state.answers[answerId]))

  return { ...question, selectedAnswers }
}

const mergeConfigs = (
  defaultConfig: CustomizerProduct['defaultConfiguration'],
  startingPointConfig: StartingPoint['configuration']
) => {
  const result: Record<string, string | string[] | null> = {}
  assign(result, defaultConfig, startingPointConfig)
  return result
}

export type ApplyStartingPointAction = {
  payload: { startingPoint?: StartingPoint }
}

const applyStartingPoint = (state: CustomizationState, { payload }: ApplyStartingPointAction): CustomizationState => {
  const config = payload.startingPoint
    ? mergeConfigs(state.customizerProduct.defaultConfiguration, payload.startingPoint.configuration)
    : state.customizerProduct.defaultConfiguration
  const questions = Object.values(state.questions).reduce(
    (questions, question) => ({
      ...questions,
      [question.id]: question.isMultiAnswer
        ? applyMultiAnswerStartingPoint({
            state,
            question,
            config,
          })
        : applySingleAnswerStartingPoint({
            state,
            question,
            config,
          }),
    }),
    {}
  )

  return { ...state, questions }
}

export default applyStartingPoint
