import { memoize } from 'lodash'

type TestCanvasSizeForBrowserArg = { width: number; height: number }

const freeCanvasFromMemory = (canvas: HTMLCanvasElement) => {
  canvas.height = 0
  canvas.width = 0
}

const memoizeResolver = (arg: TestCanvasSizeForBrowserArg) => JSON.stringify(arg)

const testCanvasSizeForBrowser = memoize(({ width, height }: TestCanvasSizeForBrowserArg) => {
  const cropCanvas = document.createElement('canvas')
  cropCanvas.width = 1
  cropCanvas.height = 1
  const testCanvas = document.createElement('canvas')
  testCanvas.width = width
  testCanvas.height = height

  const cropContext = cropCanvas.getContext('2d')
  const testContext = testCanvas.getContext('2d')

  if (cropContext === null || testContext === null) return false

  testContext.fillRect(width - 1, height - 1, 1, 1)
  cropContext.drawImage(testCanvas, width - 1, height - 1, 1, 1, 0, 0, 1, 1)

  const isSizeValid = cropContext.getImageData(0, 0, 1, 1).data[3] !== 0

  freeCanvasFromMemory(cropCanvas)
  freeCanvasFromMemory(testCanvas)

  return isSizeValid
}, memoizeResolver)

export default testCanvasSizeForBrowser
