const smartResize = (
  dimensions,
  targetDimensions,
  options = {
    behavior: 'default',
  }
) => {
  const widthRatio = targetDimensions.width / dimensions.width
  const heightRatio = targetDimensions.height / dimensions.height
  let boundingBoxScale = Math.min(widthRatio, heightRatio)

  if (options.behavior === 'shrink') boundingBoxScale = Math.min(1, boundingBoxScale)

  if (options.behavior === 'enlarge') boundingBoxScale = Math.max(1, boundingBoxScale)

  return {
    width: dimensions.width * boundingBoxScale,
    height: dimensions.height * boundingBoxScale,
  }
}

export default smartResize
