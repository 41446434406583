import tinycolor from 'tinycolor2'

import ImageLoader from 'utils/loaders/ImageLoader'
import smartResize from 'utils/smartResize'

import blackAndWhiteFilter from './filters/blackAndWhiteFilter'
import colorFilter from './filters/colorFilter'
import thresholdFilter from './filters/thresholdFilter'
import textureFilter from './filters/textureFilter'

interface ImageBuildParams {
  src?: string
  preventResize?: boolean
  allowOversize?: boolean
  position?: { x: number; y: number }
  boundingBox?: { width: number; height: number }
  scale: number
  color?: { hex?: string }
  lighting?: { lightIntensity: number; lightThreshold: number }
  texture?: { url?: string }
  filters?: { type: string; params: object }[]
}

export default class Image {
  private image

  constructor() {
    this.image = document.createElement('img')
  }

  public async build(params: ImageBuildParams) {
    if (!params.src) return

    this.image = await ImageLoader.loadImage(params.src, { preventResize: !!params.preventResize })
    this.resize(params)
    await this.applyFilters(params)
  }

  private resize(params: ImageBuildParams) {
    if (!params.boundingBox?.width || !params.boundingBox?.height) return

    const { width, height } = smartResize(
      this.image,
      {
        width: params.boundingBox.width * params.scale,
        height: params.boundingBox.height * params.scale,
      },
      {
        behavior: params.allowOversize ? 'default' : 'shrink',
      }
    )

    this.image.width = width
    this.image.height = height
  }

  public toCanvas() {
    const canvas = document.createElement('canvas')
    canvas.width = this.image.width
    canvas.height = this.image.height

    const context = canvas.getContext('2d')
    if (!context) return canvas

    context.drawImage(this.image, 0, 0, this.image.width, this.image.height)
    return canvas
  }

  private imageElementToImageData() {
    const canvas = document.createElement('canvas')
    canvas.width = this.image.width
    canvas.height = this.image.height

    const context = canvas.getContext('2d')

    if (!context) return
    context!.drawImage(this.image, 0, 0, this.image.width, this.image.height)
    return context!.getImageData(0, 0, this.image.width, this.image.height)
  }

  private imageDataToImageElement(image: ImageData) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext('2d')!.putImageData(image, 0, 0)

      const newImage = new window.Image(this.image.width, this.image.height)
      newImage.onload = () => resolve(newImage)
      newImage.onerror = () => reject(new Error('Unable to apply filters to image'))
      newImage.src = canvas.toDataURL()
      canvas.width = 0
      canvas.height = 0
    })
  }

  private async applyFilters(params: ImageBuildParams) {
    const imageData = this.imageElementToImageData()
    if (imageData) {
      await this.applyTextureFilter(imageData, params)
      this.applyColorFilter(imageData, params)
      this.applyBlackAndWhiteFilter(imageData, params)
      this.applyBlackToTransparencyFilter(imageData, params)

      this.image = await this.imageDataToImageElement(imageData)
    }
  }

  private async applyTextureFilter(imageData: ImageData, params: ImageBuildParams) {
    if (!params.texture?.url) return

    const image = await ImageLoader.loadImage(params.texture.url)

    const textureCanvas = document.createElement('canvas')
    textureCanvas.width = this.image.width
    textureCanvas.height = this.image.height
    const textureContext = textureCanvas.getContext('2d')!

    for (let i = 1; (i - 1) * image.width < textureCanvas.width; i++) {
      for (let j = 1; (j - 1) * image.height < textureCanvas.height; j++) {
        textureContext.drawImage(image, (i - 1) * image.width, (j - 1) * image.height)
      }
    }

    const textureData = textureContext.getImageData(0, 0, this.image.width, this.image.height)

    textureFilter(imageData, textureData)
  }

  private applyColorFilter(imageData: ImageData, params: ImageBuildParams) {
    const rgbColor = params.color?.hex ? tinycolor(params.color.hex).toRgb() : null
    if (rgbColor) colorFilter(imageData, { color: rgbColor, lighting: params.lighting })
  }

  private applyBlackAndWhiteFilter(imageData: ImageData, params: ImageBuildParams) {
    const blackAndWhite = params.filters?.find(({ type }) => type === 'blackAndWhite')?.params
    if (blackAndWhite) blackAndWhiteFilter(imageData)
  }

  private applyBlackToTransparencyFilter(imageData: ImageData, params: ImageBuildParams) {
    const blackToTransparency = params.filters?.find(({ type }) => type === 'blackToTransparency')?.params
    if (blackToTransparency) thresholdFilter(imageData)
  }

  public toImage() {
    return this.image
  }
}
