import Konva from 'konva'

import ViewContainer from './ViewContainer'

class MultiAnswer extends Konva.Group {
  getViewContainer() {
    return (this.getAncestors() as ViewContainer[]).find(node => node.isViewContainer)
  }
}

export default MultiAnswer
