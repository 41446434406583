export default async (fontSrc: string) => {
  return fetch(fontSrc, { method: 'GET' })
    .then(response => response.blob())
    .then(convertToBase64)
}

const convertToBase64 = (file: Blob) => {
  const reader = new FileReader()
  const promise = new Promise(resolve => {
    reader.onloadend = e => {
      const binaryData = e.target!.result as string
      return resolve(window.btoa(binaryData))
    }
  })

  reader.readAsBinaryString(file)
  return promise
}
