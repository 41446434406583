import { Asset, FontAssets } from '@packages/types'

import getExtension from './getExtension'

const getFontAssetsFromAsset = (asset?: FontAssets | Asset) => {
  if (asset && 'id' in asset) {
    return { [getExtension(asset.filename)]: { ...asset } }
  }

  return asset
}

export default getFontAssetsFromAsset
