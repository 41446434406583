const blackAndWhiteFilter = (imageData: ImageData) => {
  for (let i = 0; i < imageData.data.length; i += 4) {
    const brightness = 0.34 * imageData.data[i] + 0.5 * imageData.data[i + 1] + 0.16 * imageData.data[i + 2]
    imageData.data[i] = brightness
    imageData.data[i + 1] = brightness
    imageData.data[i + 2] = brightness
  }
}

export default blackAndWhiteFilter
