"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const createRelation = (schemaId, relation) => {
    relation.toString = () => schemaId;
    return relation;
};
const oneToOne = schemaId => {
    return createRelation(schemaId, (run, db, id, options) => {
        return run(db, id, schemaId, options);
    });
};
const manyToMany = schemaId => {
    return createRelation(schemaId, (run, db, ids, options) => {
        const res = [];
        for (const id of ids) {
            res.push(run(db, id, schemaId, options));
        }
        return res;
    });
};
exports.default = {
    oneToOne,
    manyToMany,
};
