"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Database {
    constructor(path) {
        this.path = path;
        this.collections = {};
    }
    setCollections(collections) {
        this.collections = collections;
    }
    addItem(collectionId, entity) {
        this.collections[collectionId] = this.collections[collectionId] || {};
        const collection = this.getCollection(collectionId);
        collection[entity.id] = entity;
    }
    getItem(collectionId, id) {
        const collection = this.getCollection(collectionId);
        if (!collection)
            return;
        return collection[id];
    }
    getCollection(collectionId) {
        return this.path ? this.collections[collectionId][this.path] : this.collections[collectionId];
    }
}
exports.default = Database;
