import { times } from 'lodash'

// adds a space after each consecutive line break (except the last one) to support the <br /> tag properly in firefox
// https://stackoverflow.com/questions/45087054/br-is-not-friendly-with-the-flexbox
const addSpacesToConsecutiveLineBreaks = (text: string) => {
  return text.replace(/(\n){2,}/g, consecutiveLineBreaksMatch => {
    const numberOfConsecutiveLineBreak = (consecutiveLineBreaksMatch.match(/\n/g) || []).length
    const updatedLineBreaks = times(numberOfConsecutiveLineBreak - 1, () => '\n ').join('') + '\n'

    return consecutiveLineBreaksMatch.replace(consecutiveLineBreaksMatch, updatedLineBreaks)
  })
}

export default addSpacesToConsecutiveLineBreaks
