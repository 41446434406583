"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (entities, prop, prefix, separator = ' ', suffixFirst = false) => {
    const index = entities.reduce((index, current) => {
        if (current[prop].includes(prefix)) {
            const currentIndex = Number(current[prop].split(separator).pop());
            if (Number.isNaN(currentIndex))
                return index > 0 ? index : 1;
            return currentIndex >= index ? currentIndex + 1 : index;
        }
        return index;
    }, 0);
    return `${prefix}${index === 0 ? (suffixFirst ? `${separator}${1}` : '') : `${separator}${index}`}`;
};
