import { useEffect, useRef } from 'react'
import * as ResizeObserver from 'resize-observer-polyfill'

const useObserveDimensionsOnResize = (
  observedRef: React.RefObject<HTMLElement | null>,
  onResize: (dimensions: { width: number; height: number }) => void
) => {
  const resizeObserver = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    if (!observedRef.current) return

    resizeObserver.current = new ResizeObserver.default(info => {
      const { width, height } = info[0].contentRect

      return onResize({ width, height })
    })
    resizeObserver.current.observe(observedRef.current)

    return () => {
      resizeObserver.current?.disconnect()
      resizeObserver.current = null
    }
  }, [observedRef])
}

export default useObserveDimensionsOnResize
