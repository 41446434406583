import { AnyAction } from 'redux'

import type { CustomizationState } from './reducer'

const setQuestionHasRequiredError = (
  state: CustomizationState,
  { payload: { questionId, hasRequiredError } }: AnyAction
) => {
  return {
    ...state,
    questions: {
      ...state.questions,
      [questionId]: {
        ...state.questions[questionId],
        hasRequiredError,
      },
    },
  }
}

export default setQuestionHasRequiredError
