const thresholdFilter = (
  imageData: ImageData,
  { maximumOpacity, threshold } = { maximumOpacity: 255, threshold: 50 }
) => {
  const ratio = 255 / (255 - threshold)

  for (let i = 0; i < imageData.data.length; i += 4) {
    if (imageData.data[i + 3] == 0) continue
    const r = imageData.data[i]
    const g = imageData.data[i + 1]
    const b = imageData.data[i + 2]
    const whiteValue = 0.2126 * r + 0.7152 * g + 0.0722 * b
    const opacity = (whiteValue - threshold) * ratio
    const maxOpacity = imageData.data[i + 3] < maximumOpacity ? imageData.data[i + 3] : maximumOpacity
    imageData.data[i + 3] = opacity >= maxOpacity ? maxOpacity : opacity
  }
}

export default thresholdFilter
