"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const normalize_1 = require("../normalize");
const schemas_1 = require("./schemas");
const denormalizer = new normalize_1.Denormalizer();
denormalizer.registerSchema('customizerProducts', schemas_1.customizerProductSchema);
denormalizer.registerSchema('products', schemas_1.productSchema);
denormalizer.registerSchema('parts', schemas_1.partSchema);
denormalizer.registerSchema('children', schemas_1.childrenSchema);
denormalizer.registerSchema('questions', schemas_1.questionSchema);
denormalizer.registerSchema('answers', schemas_1.answerSchema);
denormalizer.registerSchema('groups', schemas_1.groupSchema);
denormalizer.registerSchema('printAreas', schemas_1.printAreaSchema);
denormalizer.registerSchema('partDrawingProperty', schemas_1.partDrawingPropertySchema);
exports.default = denormalizer;
