"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const normalize_1 = require("../normalize");
const schemas_1 = require("./schemas");
const normalizer = new normalize_1.Normalizer();
normalizer.registerSchema('customizerProducts', schemas_1.customizerProductSchema);
normalizer.registerSchema('products', schemas_1.productSchema);
normalizer.registerSchema('parts', schemas_1.partSchema);
normalizer.registerSchema('children', schemas_1.childrenSchema);
normalizer.registerSchema('questions', schemas_1.questionSchema);
normalizer.registerSchema('answers', schemas_1.answerSchema);
normalizer.registerSchema('groups', schemas_1.groupSchema);
normalizer.registerSchema('printAreas', schemas_1.printAreaSchema);
normalizer.registerSchema('partDrawingProperty', schemas_1.partDrawingPropertySchema);
exports.default = normalizer;
