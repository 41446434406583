import { Answer } from '@packages/types'
import { cloneDeep, uniq } from 'lodash'

import type { CustomizationState } from './reducer'

export type ApplyDesignAction = {
  payload: {
    configuration?: Record<string, string | string[]>
    personalisations: Record<string, Answer>
  }
}

export default (
  state: CustomizationState,
  { payload: { configuration, personalisations } }: ApplyDesignAction
): CustomizationState => {
  if (!configuration) return state

  const configurationToApply = Array.isArray(configuration) ? configuration[0].configuration : configuration

  const personalisationsToApply = Array.isArray(personalisations)
    ? personalisations.reduce((obj, personalisation) => ({ ...obj, ...personalisation }))
    : personalisations

  return Object.entries(configurationToApply).reduce(
    (state, [questionId, configurationValue]) => {
      const question = cloneDeep(state.questions[questionId])

      if (question.isMultiAnswer) {
        question.selectedAnswers = (configurationValue as string[]) ?? []
        question.answers = uniq([...question.answers, ...(configurationValue as string[])])
        if (question.isInteractionRequired) question.hadInteraction = question.selectedAnswers.length > 0
      } else {
        question.selectedAnswer = configurationValue as string
        question.answers = uniq([...question.answers, configurationValue as string])
        if (question.isInteractionRequired) question.hadInteraction = !!question.selectedAnswer
      }

      return { ...state, questions: { ...state.questions, [questionId]: question } }
    },
    { ...state, answers: { ...state.answers, ...personalisationsToApply } }
  )
}
