"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ArrayDatabase {
    constructor(path) {
        this.path = path;
        this.collections = {};
        this.collectionsMap = {};
    }
    setCollections(collections) {
        this.collections = collections;
        this.collectionsMap = Object.keys(collections).reduce((collectionsMap, key) => ({
            ...collectionsMap,
            [key]: collections[key].reduce((keyIndex, entity, index) => ({
                ...keyIndex,
                [entity.id]: index,
            }), {}),
        }), {});
    }
    addItem(collectionId, entity) {
        this.collections[collectionId] = this.collections[collectionId] || [];
        this.collectionsMap[collectionId] = this.collectionsMap[collectionId] || {};
        const collection = this.getCollection(collectionId);
        if (this.collectionsMap[collectionId][entity.id])
            return;
        this.collectionsMap[collectionId][entity.id] = this.collections[collectionId].length;
        collection.push(entity);
    }
    getItem(collectionId, id) {
        const collection = this.getCollection(collectionId);
        if (!collection)
            return;
        return collection[this.collectionsMap[collectionId][id]];
    }
    getCollection(collectionId) {
        return this.path ? this.collections[collectionId][this.path] : this.collections[collectionId];
    }
}
exports.default = ArrayDatabase;
