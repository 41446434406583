const PHONE = 'phone'
const TABLET_PORTRAIT = 'tablet_portrait'
const TABLET_LANDSCAPE = 'tablet_landscape'
const DESKTOP = 'desktop'
const BIG_DESKTOP = 'big_desktop'

const matchesMedia = (mediaName: string) => {
  switch (mediaName) {
    case PHONE:
      return window.matchMedia('(max-width: 599px)').matches
    case TABLET_PORTRAIT:
      return window.matchMedia('(min-width: 600px) and (max-width: 768px)').matches
    case TABLET_LANDSCAPE:
      return window.matchMedia('(min-width: 769px) and (max-width: 1199px)').matches
    case DESKTOP:
      return window.matchMedia('(min-width: 1200px) and (max-width: 1799px)').matches
    case BIG_DESKTOP:
      return window.matchMedia('(min-width: 1800px)').matches
    default:
      return true
  }
}

const isMobileDisplay = () => {
  return matchesMedia(PHONE) || matchesMedia(TABLET_PORTRAIT)
}

export default isMobileDisplay
