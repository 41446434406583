import { useSelector } from 'customizer/hooks'

import { themeSelector } from './selectors'

const useThemeSettings = (settingKey: string) => {
  const theme = useSelector(themeSelector)
  return theme.settings?.[settingKey] || {}
}

export default useThemeSettings
