import { AnyAction } from 'redux'

import * as twoDDisplayerActionTypes from 'customizer/2dDisplayer/actionTypes'
import * as customizationTypes from 'customizer/customization/actionTypes'

import * as actionTypes from './actionTypes'
import { MobileDescription } from './types'
export type QuestionPanelState = {
  currentStep: string | null
  selectedSubStep: string | null
  isSubmitting: boolean
  showInteractionRequiredWarnings: boolean
  showSubmitError: boolean
  isSubmitValid: boolean
  showFinishCustomization: boolean
  mobileDescription: MobileDescription | null
  visitedQuestions: string[]
}

const initialState = {
  currentStep: null,
  selectedSubStep: null,
  isSubmitting: false,
  showInteractionRequiredWarnings: false,
  showSubmitError: false,
  showFinishCustomization: false,
  isSubmitValid: true,
  mobileDescription: null,
  visitedQuestions: [],
}

export default (state: QuestionPanelState = initialState, action: AnyAction): QuestionPanelState => {
  switch (action.type) {
    case customizationTypes.START_DESIGN_CUSTOMIZATION:
    case customizationTypes.START_CUSTOMIZATION:
      return {
        ...state,
        currentStep: action.payload.customizerProduct.tree.id,
      }
    case actionTypes.ADD_TO_CART:
      return {
        ...state,
        isSubmitting: true,
        showSubmitError: false,
      }
    case actionTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      }
    case actionTypes.SELECT_STEP:
      return {
        ...state,
        currentStep: action.payload.stepId,
      }
    case twoDDisplayerActionTypes.SELECT_STEP:
      if (!action.payload.stepId) return state

      return {
        ...state,
        currentStep: action.payload.stepId,
      }
    case actionTypes.SET_SHOW_INTERACTION_REQUIRED_WARNINGS:
      return { ...state, showInteractionRequiredWarnings: action.payload.showInteractionRequiredWarnings }
    case actionTypes.SET_IS_SUBMIT_VALID:
      return { ...state, isSubmitValid: action.payload }
    case actionTypes.SHOW_SUBMIT_ERROR:
      return { ...state, showSubmitError: action.payload }
    case customizationTypes.SET_SHOW_FINISH_CUSTOMIZATION:
      return { ...state, showFinishCustomization: action.payload }
    case actionTypes.SET_MOBILE_DESCRIPTION:
      return { ...state, mobileDescription: action.payload }
    case actionTypes.ADD_VISITED_QUESTIONS:
      return {
        ...state,
        visitedQuestions: !state.visitedQuestions.includes(action.payload)
          ? [...state.visitedQuestions, action.payload]
          : state.visitedQuestions,
      }
    default:
      return state
  }
}
