import { AnswerType } from '@packages/types'
import { AnyAction } from 'redux'

import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

const createLogoAnswer = (state: CustomizationState, { payload }: AnyAction) => {
  const views = state.customizerProduct.views
  const question = state.questions[payload.questionId]
  const answer = {
    id: payload.answerId,
    name: payload.asset.originalFilename.split('.')[0],
    type: AnswerType.Logo,
    views: Array.from(Array(views)).map(() => ({
      type: AnswerType.Logo,
      logo: payload.asset,
    })),
    isPersonalisation: true,
  }

  const newState = {
    ...state,
    questions: {
      ...state.questions,
      [payload.questionId]: {
        ...question,
        selectedAnswer: payload.answerId,
        answers: [...question.answers, payload.answerId],
      },
    },
    answers: {
      ...state.answers,
      [payload.answerId]: answer,
    },
  }

  return applyLinkedQuestions(applyRules(newState))
}

export default createLogoAnswer
