import { intersection, isEqual, sortBy, get } from 'lodash'

class AssertableObject {
  constructor(path, assertion, criticalValue) {
    this.path = path
    this.assertion = assertion
    this.criticalValue = criticalValue
  }

  evaluate(state) {
    this.retrieve(state)
    return this[this.assertion](this.criticalValue, state)
  }

  retrieve(state) {
    this.value = get(state, this.path)
  }

  is(value) {
    if (Array.isArray(this.value) && Array.isArray(value)) {
      return isEqual(sortBy(this.value), sortBy(value))
    }

    return this.value == value
  }

  isOneOf(values) {
    return values.includes(this.value)
  }

  includes(values) {
    return intersection(values, this.value).length === values.length
  }

  doesNotInclude(values) {
    return intersection(values, this.value).length !== values.length
  }

  isIn(values) {
    return values.includes(this.value)
  }

  isNotIn(values) {
    return !values.includes(this.value)
  }

  isNot(value) {
    if (Array.isArray(this.value) && Array.isArray(value)) {
      return !isEqual(sortBy(this.value), sortBy(value))
    }

    return this.value != value
  }

  isGreaterThan(value) {
    return this.value > value
  }

  isLowerThan(value) {
    return this.value < value
  }

  matches(value) {
    return this.value.match(new RegExp(value)) != null
  }

  matchesInState(value, state) {
    return this.is(get(state, value))
  }

  doesNotMatchInState(value, state) {
    return this.isNot(get(state, value))
  }

  matchesAny(values) {
    return values.includes(this.value.toUpperCase())
  }

  matchesAnyFromState(value, state) {
    return get(state, [value]).includes(this.value.toUpperCase())
  }
}

export default AssertableObject
