import { CustomizableQuestion, Rule, RuleThen, RuleThenType } from '@packages/types'
import { omit, clone } from 'lodash'

import { RootState } from 'customizer/store'

import AssertableObject from '../AssertableObject'
import { getFirstAvailableAnswer, isCurrentAnswerRestricted, validRulesSelector } from '../selectors'
import { getQuestionRestrictedAnswers } from '../utils'
import applyLinkedQuestions from './applyLinkedQuestions'
import type { CustomizationState } from './reducer'
import { selectAnswer } from './selectAnswer'
import { selectAnswers } from './selectAnswers'

const applyRules = (state: CustomizationState): CustomizationState => {
  const rules: Rule[] = validRulesSelector({ customization: state } as RootState)
  let nextState = state
  let shouldReapplyRule
  do {
    shouldReapplyRule = false

    for (const rule of rules) {
      const trigger = rule.when.reduce((isTriggered, statement) => {
        return (
          new AssertableObject(statement.path, statement.assertion, statement.value).evaluate(nextState) && isTriggered
        )
      }, true)

      const result = trigger ? activateRule(nextState, rule) : deactivateRule(nextState, rule)
      shouldReapplyRule = shouldReapplyRule || result.shouldReapplyRule
      nextState = result.nextState
    }
  } while (shouldReapplyRule)

  return applyLinkedQuestions(nextState)
}

const getRestriction = (state: CustomizationState, then: RuleThen) => {
  switch (then.type) {
    case RuleThenType.SetAuthorizedAnswers:
      return {
        questionId: then.questionId,
        type: then.type,
        answers: state.questions[then.questionId].answers.filter(id => !then.payload?.includes(id)),
      }
    case RuleThenType.SetRestrictedAnswers:
      return {
        questionId: then.questionId,
        answers: then.payload,
        type: then.type,
      }
    case RuleThenType.AddRestriction:
      return {
        questionId: then.questionId,
        answers: [then.answerId],
        type: then.type,
      }
    case RuleThenType.RestrictAllBut:
      const answers = then.payload
        ? state.questions[then.questionId].answers.filter(id => !then.payload?.includes(id))
        : state.questions[then.questionId].answers.filter(id => id !== then.answerId)

      return {
        questionId: then.questionId,
        answers,
        type: then.type,
      }
    case RuleThenType.DisableQuestion:
      return {
        questionId: then.questionId,
        answers: clone(state.questions[then.questionId].answers),
        type: then.type,
      }
  }
}

const applyRestrictions = (state: CustomizationState, question: CustomizableQuestion) => {
  const defaultConfiguration = state.customizerProduct.defaultConfiguration[question.id]
  const validAnswer = getFirstAvailableAnswer(question, state.answers, defaultConfiguration as string | null)

  return selectAnswer(state, { payload: { questionId: question.id, answerId: validAnswer } })
}

const applyMultiAnswerRestrictions = (state: CustomizationState, question: CustomizableQuestion) => {
  const restrictedAnswers = getQuestionRestrictedAnswers(question)
  const answerIds = question.selectedAnswers!.filter(id => !restrictedAnswers.includes(id))

  return selectAnswers(state, { payload: { questionId: question.id, answerIds } })
}

export const activateRule = (state: CustomizationState, rule: Rule) => {
  let nextState = state
  let shouldReapplyRule = false

  if (rule.then.length === 0) return { nextState, shouldReapplyRule }

  for (const then of rule.then) {
    if (!nextState.questions[then.questionId]) continue

    const restriction = getRestriction(nextState, then)

    if (!restriction) continue

    const question = state.questions[restriction.questionId]

    if (!question || question.restrictions![rule.id] != null) continue

    const updatedQuestion = {
      ...state.questions[question.id],
      restrictions: {
        ...state.questions[question.id].restrictions,
        [rule.id]: { answers: restriction.answers, type: restriction.type },
      },
    }

    nextState = { ...state, questions: { ...state.questions, [question.id]: updatedQuestion } }

    if (!isCurrentAnswerRestricted(updatedQuestion)) continue

    shouldReapplyRule = true
    nextState = question.isMultiAnswer
      ? applyMultiAnswerRestrictions(nextState, updatedQuestion)
      : applyRestrictions(nextState, updatedQuestion)
  }

  return { nextState, shouldReapplyRule }
}

export const deactivateRule = (state: CustomizationState, rule: Rule) => {
  let nextState = state
  let shouldReapplyRule = false

  if (rule.then.length === 0) return { nextState, shouldReapplyRule }

  for (const then of rule.then) {
    const question = state.questions[then.questionId]

    if (!question?.restrictions?.[rule.id]) continue

    const questionWithoutRestriction = { ...question, restrictions: omit(question.restrictions, rule.id) }

    if (!question.isMultiAnswer && !question.selectedAnswer) {
      questionWithoutRestriction.selectedAnswer = getFirstAvailableAnswer(
        questionWithoutRestriction,
        state.answers,
        state.customizerProduct.defaultConfiguration[question.id] as string | null
      )
      shouldReapplyRule = true
    }

    nextState = { ...state, questions: { ...state.questions, [question.id]: questionWithoutRestriction } }
  }

  return { nextState, shouldReapplyRule }
}

export default applyRules
