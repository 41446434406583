import { AnswerType } from '@packages/types'
import { AnyAction } from 'redux'

import { applyTextModifiers } from '../utils'
import applyLinkedQuestions from './applyLinkedQuestions'
import type { CustomizationState } from './reducer'

const createTextAnswer = (state: CustomizationState, { payload }: AnyAction) => {
  const views = state.customizerProduct.views
  const question = state.questions[payload.questionId]
  const text = applyTextModifiers(question.textModifiers, payload.text)
  const productViewText = payload.productViewText
    ? applyTextModifiers(question.textModifiers, payload.productViewText)
    : null

  const answer = {
    id: payload.answerId,
    name: text,
    type: AnswerType.Text,
    value: text,
    views: Array.from(Array(views)).map(() => ({
      type: AnswerType.Text,
      text: productViewText || text,
    })),
    isPersonalisation: true,
  }

  const newState = {
    ...state,
    questions: {
      ...state.questions,
      [payload.questionId]: {
        ...question,
        selectedAnswer: payload.createOnly ? question.selectedAnswer : payload.answerId,
        answers: [...question.answers, payload.answerId],
      },
    },
    answers: {
      ...state.answers,
      [payload.answerId]: answer,
    },
  }

  return applyLinkedQuestions(newState)
}

export default createTextAnswer
