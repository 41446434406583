"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomIndexes = exports.timeIndexes = void 0;
exports.timeIndexes = null;
exports.randomIndexes = null;
const bits = 36;
const timeIndexesNumber = 4;
const randomIndexesNumber = 3;
const generateUniqueString = () => {
    if (!exports.timeIndexes) {
        exports.timeIndexes = [];
        let time = new Date().getTime();
        for (let i = 0; i < timeIndexesNumber; i++) {
            exports.timeIndexes.push(time % bits);
            time = Math.floor(time / bits);
        }
    }
    if (!exports.randomIndexes) {
        exports.randomIndexes = [];
        for (let i = 0; i < randomIndexesNumber; i++) {
            exports.randomIndexes.push(Math.floor(Math.random() * bits));
        }
    }
    const indexes = exports.timeIndexes.concat(exports.randomIndexes);
    let sequence = indexes[0];
    for (let i = 1; i < indexes.length; i++) {
        sequence = (sequence << bits % 8) + indexes[i];
    }
    for (let i = 0; i < exports.randomIndexes.length; i++) {
        if (exports.randomIndexes[i - 1]) {
            break;
        }
        exports.randomIndexes[i] = (exports.randomIndexes[i] + 1) % bits;
    }
    for (let i = exports.timeIndexes.length - 1; i >= 0; i--) {
        if (exports.timeIndexes[i + 1]) {
            break;
        }
        exports.timeIndexes[i] = (exports.timeIndexes[i] + 1) % bits;
    }
    return ('000000' + sequence.toString(36)).slice(-6);
};
exports.default = generateUniqueString;
