import { FontAssets, FontMimeTypes, supportedFontExtensions } from '@packages/types'

import CustomFontCache from './CustomFontCache'
import encodeFontToBase64 from './encodeFontToBase64'

class CustomFontLoader {
  async loadFont(font: string, assets?: FontAssets): Promise<void | null | FontFace> {
    if (!font || !this.validateFontAssets(assets)) return Promise.resolve()
    if (CustomFontCache.verifyIfFontIsCached(font, assets)) return CustomFontCache.fonts[font].promise

    CustomFontCache.addFontToCache(font, assets)

    const extension = supportedFontExtensions.find(extension => !!assets[extension])
    if (!extension) return Promise.resolve()

    const asset = assets[extension]
    const url = typeof asset === 'string' ? asset : asset!.url
    const fontFace = new FontFace(font, `url(${url})`)

    document.fonts.add(fontFace)

    CustomFontCache.updateFont(font, { promise: fontFace.load() })

    return CustomFontCache.fonts[font].promise
  }

  async generateEncodedFontCss(font: string, assets?: FontAssets) {
    if (!this.validateFontAssets(assets)) return

    if (!CustomFontCache.verifyIfFontIsCached(font, assets)) CustomFontCache.addFontToCache(font, assets)

    if (!!CustomFontCache.fonts[font].base64) return CustomFontCache.fonts[font].base64

    const objKeys = Object.keys(assets).filter(key => !!assets[key as keyof FontAssets]) as (keyof Omit<
      FontAssets,
      'google'
    >)[]

    const srcStrings = await Promise.all(
      objKeys.map(async extension => {
        const value = assets[extension]!
        const srcUrl = typeof value === 'object' ? value.url : value
        const encoded = await encodeFontToBase64(srcUrl)

        return `url(data:${FontMimeTypes[extension]};charset=utf-8;base64,${encoded})`
      })
    )

    const encoded = `@font-face { font-family: '${font}'; src: ${srcStrings.join(', ')}; }`
    CustomFontCache.fonts[font].base64 = encoded

    return encoded
  }

  validateFontAssets(srcs?: FontAssets): srcs is FontAssets {
    if (srcs == null) return false

    return !!Object.values(srcs).find(src => src != null)
  }
}

export default new CustomFontLoader()
