export const SWITCH_VIEW = '2ddisplayer/switch-view'
export const SET_ZOOM = '2ddisplayer/set-zoom'
export const DISABLE_ZOOM = '2ddisplayer/disable-zoom'
export const ENABLE_ZOOM = '2ddisplayer/enable-zoom'
export const HIGHLIGHT_PART = '2ddisplayer/highlight-part'
export const SELECT_STEP = '2ddisplayer/select-step'
export const UPDATE_PRINT_AREA = '2ddisplayer/update-print-area'
export const DISPLAY_GRID = '2ddisplayer/display-grid'
export const UPDATE_RENDER_STATE = '2ddisplayer/update-render-state'
export const SET_IS_MOBILE = '2ddisplayer/set-is-mobile'
export const SET_DISPLAYER_SIZE = '2ddisplayer/set-displayer-size'
export const EDIT_PRINT_AREA = 'customization/edit-print-area'
