import { omit } from 'lodash'
import { AnyAction } from 'redux'

import { getFirstAvailableAnswer } from '../selectors'
import { getQuestionRestrictedAnswers } from '../utils'
import type { CustomizationState } from './reducer'
import selectAnswer from './selectAnswer'
import selectAnswers from './selectAnswers'

export default (state: CustomizationState, { payload: { questionId, answerId } }: AnyAction) => {
  const question = state.questions[questionId]

  const nextState: CustomizationState = {
    ...state,
    answers: omit(state.answers, answerId),
    questions: {
      ...state.questions,
      [questionId]: {
        ...question,
        answers: question.answers.filter(id => id !== answerId),
      },
    },
  }

  const isAnswerSelected = question.isMultiAnswer
    ? question.selectedAnswers?.includes(answerId)
    : question.selectedAnswer == answerId

  if (!isAnswerSelected) return nextState

  if (question.isMultiAnswer) {
    const restrictedAnswers = getQuestionRestrictedAnswers(nextState.questions[questionId])
    const answerIds = question.selectedAnswers?.filter(id => !restrictedAnswers.includes(id) && id != answerId) || []

    return selectAnswers(nextState, { payload: { questionId, answerIds } })
  }

  const defaultAnswer = nextState.customizerProduct.defaultConfiguration[questionId] as string | null
  const nextSelectedAnswer = getFirstAvailableAnswer(nextState.questions[questionId], nextState.answers, defaultAnswer)

  return selectAnswer(nextState, { payload: { questionId, answerId: nextSelectedAnswer } })
}
