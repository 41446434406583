import { PrintAreaMeasurementUnit } from '@packages/types'

const CM_PER_INCH = 2.54
const MM_PER_INCH = 25.4

export const CONVERSIONS_RATES_TO_INCHES = {
  [PrintAreaMeasurementUnit.Inches]: 1,
  [PrintAreaMeasurementUnit.Centimeters]: CM_PER_INCH,
  [PrintAreaMeasurementUnit.Millimeters]: MM_PER_INCH,
}
