const isNullOrEmpty = <T>(value: T | null | undefined): value is null | undefined => {
  return (
    value === '' ||
    value == null ||
    (value && (value as any[]).length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  )
}

export default isNullOrEmpty
