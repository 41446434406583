import Konva from 'konva'
import { Canvas as KonvaCanvas } from 'konva/lib/Canvas'
import { ImageConfig } from 'konva/lib/shapes/Image'

import { toRads } from 'utils/math'

import type {
  GlobalCompositePostProcessingOperation,
  ImageNode,
  MaskPostProcessingOperation,
  TextNode,
} from './../types/node'
import type ProductStage from './ProductStage'
import type ViewContainer from './ViewContainer'

type ImageKeys = keyof Pick<
  Konva.Image,
  'image' | 'x' | 'y' | 'width' | 'height' | 'offsetX' | 'offsetY' | 'rotation' | 'globalCompositeOperation'
>

const fieldSettersToOverride = [
  'image',
  'x',
  'y',
  'width',
  'height',
  'offsetX',
  'offsetY',
  'rotation',
  'globalCompositeOperation',
] as ImageKeys[]

export class CacheCanvas extends KonvaCanvas {
  hitCanvas = true

  constructor(config: any) {
    super(config)
    this.context = new Konva.Context(this)
    this.context._context = this._canvas.getContext('2d')!
    this.setSize(config.width, config.height)
  }
}

export default class Part extends Konva.Image {
  private _dirty

  partMasks?: Part[]
  maskCanvas?: HTMLCanvasElement
  maskId?: string
  lastDraw = 0

  constructor(config: ImageConfig) {
    super({ ...config, listening: false, preventDefault: false })

    this._dirty = false
    this.partMasks = []
    this.filters([])
  }

  set dirty(dirty) {
    this._dirty = dirty
  }

  get dirty() {
    if (!this.partMasks || this.partMasks.length === 0) return this._dirty
    return this._dirty || this.getDirtyMask() != null
  }

  override remove() {
    this.dirty = true
    return super.remove()
  }

  override getStage() {
    return super.getStage() as ProductStage
  }

  public getBoundaries() {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }
  }

  protected imageAsCanvas() {
    const image = this.image()

    if (!image) return

    if (image instanceof HTMLCanvasElement) return image

    if (!(image instanceof HTMLImageElement)) return

    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')!
    context.drawImage(image, 0, 0)
    return canvas
  }

  render(node: ImageNode | TextNode) {
    this.setAttr('nodeData', node)
    this.listening(node.focusable)
  }

  getDirtyMask(): Part | undefined {
    return this.partMasks?.find(part => {
      return part.getLastDraw() > this.lastDraw
    })
  }

  getViewContainer() {
    return this.getAncestors().find(node => (node as ViewContainer).isViewContainer) as ViewContainer | undefined
  }

  getMaskedImage(): CanvasImageSource | HTMLCanvasElement {
    if (!this.partMasks || this.partMasks.length === 0 || !this.attrs.image || !this.getParent())
      return this.attrs.image

    const width = this.getWidth() * this.scaleX()
    const height = this.getHeight() * this.scaleY()

    const bufferCanvas = document.createElement('canvas')
    const bufferCanvasContext = bufferCanvas.getContext('2d')!

    bufferCanvas.width = width
    bufferCanvas.height = height

    bufferCanvasContext.save()

    const { x, y } = this.getAbsolutePosition(this.getViewContainer())

    const { x: scaleX, y: scaleY } = this.scale()!

    const { x: parentScaleX, y: parentScaleY } = this.getParent().scale()!

    const position = {
      x: -x + this.offsetX() * scaleX * parentScaleX,
      y: -y + this.offsetY() * scaleY * parentScaleY,
    }

    bufferCanvasContext.translate(this.offsetX(), this.offsetY())

    bufferCanvasContext.rotate(toRads(-this.getAbsoluteRotation()))

    bufferCanvasContext.translate(-this.offsetX(), -this.offsetY())

    bufferCanvasContext.scale(1 / parentScaleX, 1 / parentScaleY)

    this.partMasks.forEach(part => {
      const image = part.image()
      if (!image) return
      bufferCanvasContext.save()
      bufferCanvasContext.scale(part.scaleX(), part.scaleY())

      bufferCanvasContext.drawImage(image, position.x / part.scaleX(), position.y / part.scaleY())
      bufferCanvasContext.restore()
    })

    bufferCanvasContext.restore()

    bufferCanvasContext.globalCompositeOperation = 'source-in'
    bufferCanvasContext.drawImage(this.attrs.image, 0, 0, width, height)

    this.maskCanvas = bufferCanvas

    return bufferCanvas
  }

  override _sceneFunc(context: Konva.Context) {
    const width = this.getWidth() as number
    const height = this.getHeight() as number
    const image = this.getMaskedImage()

    const getParams = ():
      | [CanvasImageSource, number, number, number, number, number, number, number, number]
      | [CanvasImageSource, number, number, number, number] => {
      const cropWidth = this.attrs.cropWidth
      const cropHeight = this.attrs.cropHeight
      if (cropWidth && cropHeight) {
        return [image, this.cropX(), this.cropY(), cropWidth, cropHeight, 0, 0, width, height]
      }
      return [image, 0, 0, width, height]
    }

    if (this.hasFill() || this.hasStroke()) {
      context.beginPath()
      context.rect(0, 0, width, height)
      context.closePath()
      context.fillStrokeShape(this as Konva.Shape)
    }

    if (image) {
      // eslint-disable-next-line prefer-spread
      context.drawImage.apply(context, getParams())
    }

    this.lastDraw = performance.now()
    this.dirty = false
  }

  getLastDraw() {
    return this.lastDraw
  }

  mask(nodesIds?: string[]) {
    if (nodesIds?.length) {
      const viewContainer = this.getViewContainer()
      if (!viewContainer) return
      const parts = nodesIds.map(id => viewContainer.findOne(`#${id}`)).filter(sprite => sprite != null) as Part[]
      this.setMaskFromParts(parts)
    } else {
      this.removeMask()
    }
  }

  setMaskFromParts(parts: Part[]) {
    const maskId = parts.map(part => part.id()).join()

    if (this.maskId === maskId) return

    this.maskId = maskId
    this.partMasks = parts
    this.dirty = true
  }

  removeMask() {
    if (!this.maskId) return

    this.maskId = undefined
    this.dirty = true
    this.partMasks = undefined
  }

  handleMouseEnter = () => {
    const stage = this.getStage()
    if (!stage) return
    stage.container().style.cursor = 'move'
  }

  handleMouseLeave = () => {
    const stage = this.getStage()
    if (!stage) return
    stage.container().style.cursor = 'pointer'
  }

  async applyPostProcessingOperations({ postProcessingOperations }: TextNode | ImageNode) {
    const maskOperation = postProcessingOperations.find(({ type }) => type === 'mask') as
      | MaskPostProcessingOperation
      | undefined
    await this.mask(maskOperation?.nodesIds)

    const globalCompositeOperation = postProcessingOperations.find(
      ({ type }) => type === 'globalCompositeOperation'
    ) as GlobalCompositePostProcessingOperation | undefined
    this.globalCompositeOperation(globalCompositeOperation?.value ?? 'source-over')
  }
}

type GetType<K extends keyof Konva.Image> = Konva.Image[K] extends never ? never : Konva.Image[K]

fieldSettersToOverride.forEach(field => {
  const getSet = function (this: Part, value: any) {
    const parent = Konva.Image.prototype[field].bind(this)

    if (value === undefined) return parent()

    if (parent() === value) return parent()

    parent.bind(this)(value)
    this.dirty = true

    return parent()
  } as GetType<typeof field>

  Object.assign(Part.prototype, { [field]: getSet })
})
