import { AnswerType } from '@packages/types'
import { AnyAction } from 'redux'

import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

const createAssetAnswer = (state: CustomizationState, { payload }: AnyAction) => {
  const question = state.questions[payload.questionId]
  const answer = {
    id: payload.answerId,
    name: payload.value.originalFilename.split('.').slice(0, -1).join('.'),
    type: AnswerType.Asset,
    value: payload.value,
    isPersonalisation: true,
  }

  const newState = {
    ...state,
    questions: {
      ...state.questions,
      [payload.questionId]: {
        ...question,
        selectedAnswer: payload.answerId,
        answers: [...question.answers, payload.answerId],
      },
    },
    answers: {
      ...state.answers,
      [payload.answerId]: answer,
    },
  }

  return applyLinkedQuestions(applyRules(newState))
}

export default createAssetAnswer
