"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class MissingEntityError extends Error {
    constructor(entityId, collectionId) {
        super(`Could not find entity with id ${entityId} in collection ${collectionId}`);
        this.entityId = entityId;
        this.collectionId = collectionId;
    }
}
exports.default = MissingEntityError;
