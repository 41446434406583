import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

export type SelectAnswerAction = {
  payload: {
    answerId: string | null | undefined
    questionId: string
  }
}

export const selectAnswer = (
  state: CustomizationState,
  { payload: { answerId, questionId } }: SelectAnswerAction
): CustomizationState => {
  const question = state.questions[questionId]
  let nextState: CustomizationState

  if (question.isMultiAnswer) {
    const { selectedAnswers = [] } = question

    const updatedSelectedAnswers = selectedAnswers.includes(answerId!)
      ? selectedAnswers.filter(answer => answer !== answerId)
      : [...selectedAnswers, answerId!]

    nextState = {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: { ...question, selectedAnswers: updatedSelectedAnswers, hadInteraction: true },
      },
    }
  } else {
    nextState = {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: { ...question, selectedAnswer: answerId, hadInteraction: true },
      },
    }
  }

  return nextState
}

export default (...args: Parameters<typeof selectAnswer>): CustomizationState => {
  return applyLinkedQuestions(applyRules(selectAnswer(...args)))
}
