class GoogleFontCache {
  fonts: Record<string, string> = {}

  addFontToCache(url: string, content: string) {
    this.fonts[url] = content
  }

  verifyIfFontIsCached(url: string) {
    return !!this.fonts[url]
  }

  updateFont(url: string, content: string) {
    this.fonts[url] = content
  }
}

export default new GoogleFontCache()
