const colorFilter = (
  imageData: ImageData,
  {
    color,
    lighting,
  }: { color?: tinycolor.ColorFormats.RGBA; lighting?: { lightIntensity: number; lightThreshold: number } }
) => {
  if (color == null) return

  const nPixels = imageData.data.length
  for (let i = 0; i < nPixels; i += 4) {
    if (imageData.data[i + 3] === 0) continue

    if (lighting) {
      const ratio = Math.min(255 / (255 - lighting.lightThreshold), 255)
      const whiteValue = 0.2126 * imageData.data[i] + 0.7152 * imageData.data[i + 1] + 0.0722 * imageData.data[i + 2]
      const whiteOverThreshold = whiteValue >= lighting.lightThreshold

      if (!whiteOverThreshold) {
        imageData.data[i] = (imageData.data[i] * color.r) / 255
        imageData.data[i + 1] = (imageData.data[i + 1] * color.g) / 255
        imageData.data[i + 2] = (imageData.data[i + 2] * color.b) / 255
        continue
      }

      const whiteOpacity = (((whiteValue - lighting.lightThreshold) * ratio) / 255.0) * lighting.lightIntensity

      imageData.data[i] = 255 * whiteOpacity + ((imageData.data[i] * color.r) / 255) * (1 - whiteOpacity)
      imageData.data[i + 1] = 255 * whiteOpacity + ((imageData.data[i + 1] * color.g) / 255) * (1 - whiteOpacity)
      imageData.data[i + 2] = 255 * whiteOpacity + ((imageData.data[i + 2] * color.b) / 255) * (1 - whiteOpacity)

      continue
    }

    imageData.data[i] = (imageData.data[i] * color.r) / 255
    imageData.data[i + 1] = (imageData.data[i + 1] * color.g) / 255
    imageData.data[i + 2] = (imageData.data[i + 2] * color.b) / 255
  }
}

export default colorFilter
