import Konva from 'konva'

import { doPolygonsIntersect, toRads, rotatePoint, Polygon } from 'utils/math'

import type Part from './Part'
import ViewContainer from './ViewContainer'

export default class GroupClientRectClip extends Konva.Group {
  dragBoundRectangle

  constructor(config?: Konva.ContainerConfig) {
    super(config)

    this.dragBoundRectangle = {
      width: 0,
      height: 0,
      offsetX: 0,
      offsetY: 0,
    }
  }

  override getClientRect(config?: any) {
    const attrs = this.attrs
    const clientRect = super.getClientRect(config)
    if (attrs.hasOwnProperty('clipWidth')) {
      clientRect.width = attrs.clipWidth * attrs.scaleX
      clientRect.x = attrs.clipX * attrs.scaleX
    }
    if (attrs.hasOwnProperty('clipHeight')) {
      clientRect.height = attrs.clipHeight * attrs.scaleY
      clientRect.y = attrs.clipY * attrs.scaleY
    }
    return clientRect
  }

  override add(child: Part) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    const printArea = this.getParent()

    child.dragBoundFunc(function (pos: { x: number; y: number }) {
      const scale = this.getStage()!.scaleX()

      const parent = this.getParent() as unknown as GroupClientRectClip

      const width = this.width() * scale
      const height = this.height() * scale

      const parentPos = printArea.getAbsolutePosition()
      const dragRectPos = {
        x: parentPos.x + that.dragBoundRectangle.offsetX * scale,
        y: parentPos.y + that.dragBoundRectangle.offsetY * scale,
      }

      const dragRectWidth = parent.dragBoundRectangle.width * scale
      const dragRectHeight = parent.dragBoundRectangle.height * scale

      const offsetX = this.offsetX() * scale
      const offsetY = this.offsetY() * scale

      const points = [
        { x: pos.x - offsetX, y: pos.y - offsetY },
        { x: pos.x + width - offsetX, y: pos.y - offsetY },
        { x: pos.x + width - offsetX, y: pos.y + height - offsetY },
        { x: pos.x - offsetX, y: pos.y + height - offsetY },
      ]

      const rotatedPoints = points.map(p =>
        rotatePoint(p, toRads(this.rotation() + printArea.rotation()), {
          x: pos.x,
          y: pos.y,
        })
      )

      const parentPoints = [
        { x: dragRectPos.x, y: dragRectPos.y },
        { x: dragRectPos.x + dragRectWidth, y: dragRectPos.y },
        { x: dragRectPos.x + dragRectWidth, y: dragRectPos.y + dragRectHeight },
        { x: dragRectPos.x, y: dragRectPos.y + dragRectHeight },
      ]

      const rotatedParentPoints = parentPoints.map(p => rotatePoint(p, toRads(printArea.rotation()), parentPos))

      if (!doPolygonsIntersect(rotatedParentPoints as Polygon, rotatedPoints as Polygon)) {
        return rotatePoint(
          { x: dragRectPos.x + dragRectWidth / 2, y: dragRectPos.y + dragRectHeight / 2 },
          toRads(printArea.rotation()),
          parentPos
        )
      }

      return { x: pos.x, y: pos.y }
    })

    return super.add(child)
  }

  getViewContainer() {
    return (this.getAncestors() as ViewContainer[]).find(node => node.isViewContainer)
  }
}
