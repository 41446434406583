import { OverflowBehavior, TextVerticalAlign } from '@packages/types'
import Konva from 'konva'

import MultilineTextSVG from 'common/drawing/MultilineTextSVG'

import type { MultilineTextNode } from './../types/node'
import TextPart from './TextPart'

export default class MultilineTextPart extends TextPart {
  private padding: number
  protected override svg: MultilineTextSVG

  constructor(config: Konva.ImageConfig) {
    super(config)

    this.padding = 0
    this.svg = new MultilineTextSVG()
  }

  private calculateOffsetY(node: MultilineTextNode) {
    if (node.allowedTransforms?.resize || node.position.overflowBehavior !== OverflowBehavior.Visible)
      return this.height() / 2

    if (node.position.verticalAlign === TextVerticalAlign.Middle) return this.height() / 2

    if (node.position.verticalAlign === TextVerticalAlign.Bottom) return this.height() - this.padding * node.scale

    if (node.position.verticalAlign === TextVerticalAlign.Top) return this.padding * node.scale

    return 0
  }

  public override async render(node: MultilineTextNode) {
    super.render(node)
    this.padding =
      node.allowedTransforms?.resize || node.position.overflowBehavior !== OverflowBehavior.Clip
        ? Number((node.font.size || this.svg.defaultValues.fontSize).replace('px', ''))
        : 0

    await this.svg.build({ ...node, padding: this.padding })
    let image: HTMLImageElement | HTMLCanvasElement = await this.svg.toImage()
    const hasNeon = !!node.postProcessingOperations.find(({ type }) => type === 'neon')

    if (hasNeon) image = this.applyNeon(image, node)
    this.width(Math.floor(image.width * node.scale))
    this.height(Math.floor(image.height * node.scale))

    this.x(node.position.x * node.scale)
    this.y(node.position.y * node.scale)
    this.offsetX(this.width() / 2)
    this.offsetY(this.calculateOffsetY(node))
    this.rotation(node.position.rotation ?? 0)
    this.scale({ x: 1, y: 1 })
    this.image(image)
  }

  override destroy() {
    this.svg.destroy()
    return super.destroy()
  }
}
