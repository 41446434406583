import { PrintAreaLogoPositionAnswer } from '@packages/types'
import { AnyAction } from 'redux'

import type { CustomizationState } from './reducer'

const updatePrintAreaLogoPositionAnswer = (state: CustomizationState, { payload }: AnyAction): CustomizationState => {
  const answer = state.answers[payload.answerId] as PrintAreaLogoPositionAnswer

  return {
    ...state,
    answers: {
      ...state.answers,
      [payload.answerId]: {
        ...answer,
        isPersonalisation: true,
        position: { ...answer.position, ...payload.position },
      },
    },
  }
}

export default updatePrintAreaLogoPositionAnswer
