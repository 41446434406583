const getBoundaries = (canvas: HTMLCanvasElement) => {
  const context = canvas.getContext('2d')

  if (!context || canvas.width === 0 || canvas.height === 0)
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }

  let minX = Number.MAX_SAFE_INTEGER
  let minY = Number.MAX_SAFE_INTEGER
  let maxX = Number.MIN_SAFE_INTEGER
  let maxY = Number.MIN_SAFE_INTEGER

  const imageData = context.getImageData(0, 0, canvas.width, canvas.height)

  const rowLength = canvas.width * 4
  const rowCount = imageData.data.length / rowLength

  let hasContent = false

  for (let i = 0; i < rowCount; i += 1) {
    const y = i * rowLength
    for (let j = 0; j < rowLength; j += 4) {
      const index = y + j
      if (imageData.data[index + 3] > 0) {
        hasContent = true
        if (j / 4 < minX) minX = j / 4
        if (j / 4 > maxX) maxX = j / 4
        if (i < minY) minY = i
        if (i > maxY) maxY = i
      }
    }
  }

  if (!hasContent) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }
  }

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

export default getBoundaries
