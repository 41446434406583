import { FontSizeView } from '@packages/types'
import { AnyAction } from 'redux'

import type { CustomizationState } from './reducer'

const updateFontSizeAnswer = (state: CustomizationState, { payload }: AnyAction): CustomizationState => {
  const answer = state.answers[payload.answerId]

  return {
    ...state,
    answers: {
      ...state.answers,
      [payload.answerId]: {
        ...answer,
        isPersonalisation: true,
        views: (answer.views as FontSizeView[] | undefined)?.map(view => ({
          ...view,
          size: `${Math.round(Number(view.size.replace('px', '')) * payload.scale)}px`,
        })),
      },
    },
  }
}

export default updateFontSizeAnswer
