import { AnyAction } from 'redux'

import applyLinkedQuestions from './applyLinkedQuestions'
import applyRules from './applyRules'
import type { CustomizationState } from './reducer'

const updateColorAnswer = (state: CustomizationState, { payload }: AnyAction) => {
  const answer = state.answers[payload.answerId]

  const newState = {
    ...state,
    answers: {
      ...state.answers,
      [payload.answerId]: {
        ...answer,
        name: payload.color,
        isPersonalisation: true,
        views: answer.views!.map(view => ({ ...view, color: payload.color })),
      },
    },
  }

  return applyLinkedQuestions(applyRules(newState))
}

export default updateColorAnswer
