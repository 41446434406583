import React from 'react'
import styled from 'styled-components'

import './CircularLoadingIcon.scss'

type Style = { backgroundColor?: string; color?: string }

interface CircularLoadingIconProps {
  style: Style
  StyledCircle?: ReturnType<typeof styled.circle<any>>
  isTouch?: boolean
}

const CircularLoadingIcon = ({ style, StyledCircle, isTouch }: CircularLoadingIconProps) => {
  return (
    <svg viewBox="0 0 56 56" className="circular-loading-icon" aria-label="loading circle">
      <g>
        {StyledCircle ? (
          <StyledCircle id="spinner" $styled={style} isTouch={isTouch} cx="28" cy="28" r="16" />
        ) : (
          <circle cx="28" cy="28" id="spinner" r="16" style={{ fill: style.backgroundColor, stroke: style.color }} />
        )}
      </g>
    </svg>
  )
}

export default CircularLoadingIcon
