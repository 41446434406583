// ref : https://stackoverflow.com/a/2521335
export default function (url, { width, height }) {
  return new Promise((resolve, reject) => {
    try {
      const sourceImage = new Image()

      sourceImage.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(sourceImage, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }

      sourceImage.src = url
    } catch (error) {
      reject(error)
    }
  })
}
