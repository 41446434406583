import Konva from 'konva'

import TextOnPathSVG from 'common/drawing/TextOnPathSVG'

import type { TextOnPathNode } from './../types/node'
import TextPart from './TextPart'

export default class TextOnPathPart extends TextPart {
  protected override svg: TextOnPathSVG

  constructor(config: Konva.ImageConfig) {
    super(config)

    this.svg = new TextOnPathSVG()
  }

  public override async render(node: TextOnPathNode) {
    super.render(node)
    await this.svg.build(node)
    let image: HTMLImageElement | HTMLCanvasElement = await this.svg.toImage()

    const hasNeon = !!node.postProcessingOperations.find(({ type }) => type === 'neon')

    if (hasNeon) image = this.applyNeon(image, node)

    this.width(image.width * node.scale)
    this.height(image.height * node.scale)
    this.x(this.svg.boundingBox!.x * node.scale)
    this.y(this.svg.boundingBox!.y * node.scale)
    this.scale({ x: 1, y: 1 })

    this.image(image)
  }

  override destroy() {
    this.svg.destroy()
    return super.destroy()
  }
}
