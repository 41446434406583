import { AnyAction } from 'redux'

import { applyTextModifiers } from '../utils'
import applyLinkedQuestions from './applyLinkedQuestions'
import type { CustomizationState } from './reducer'

const updateAnswerText = (state: CustomizationState, { payload }: AnyAction) => {
  const answer = state.answers[payload.answerId]
  const question = state.questions[payload.questionId]
  const text = applyTextModifiers(question.textModifiers, payload.text)
  const productViewText = payload.productViewText
    ? applyTextModifiers(question.textModifiers, payload.productViewText)
    : null

  const nextState = {
    ...state,
    answers: {
      ...state.answers,
      [payload.answerId]: {
        ...answer,
        isPersonalisation: true,
        value: text,
        views: answer.views ? answer.views.map(view => ({ ...view, text: productViewText || text })) : undefined,
      },
    },
  }

  return applyLinkedQuestions(nextState)
}

export default updateAnswerText
