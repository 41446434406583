import { mapValues } from 'lodash'
import { AnyAction } from 'redux'

import type { CustomizationState } from './reducer'

const selectAnswerWhereAvailable = (state: CustomizationState, action: AnyAction) => {
  const { answerId } = action.payload

  const newQuestions = mapValues(state.questions, question => {
    return { ...question, selectedAnswer: question.answers?.includes(answerId) ? answerId : question.selectedAnswer }
  })

  return { ...state, questions: newQuestions }
}

export default selectAnswerWhereAvailable
