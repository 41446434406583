const textureFilter = (imageData: ImageData, textureData: ImageData) => {
  if (!textureData.data) return

  for (let i = 0; i < imageData.data.length; i += 4) {
    if (imageData.data[i + 3] === 0) continue

    imageData.data[i] = (imageData.data[i] * textureData.data[i]) / 255
    imageData.data[i + 1] = (imageData.data[i + 1] * textureData.data[i + 1]) / 255
    imageData.data[i + 2] = (imageData.data[i + 2] * textureData.data[i + 2]) / 255
  }
}

export default textureFilter
