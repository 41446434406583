import GoogleFontCache from './GoogleFontCache'

class GoogleFontLoader {
  private getFontUrl(family: string, preview?: string) {
    let googleFontUrl = `https://fonts.googleapis.com/css?family=${encodeURIComponent(family)}&display=swap`

    if (preview) {
      googleFontUrl += `&text=${encodeURIComponent(preview)}`
    }

    return googleFontUrl
  }

  private async getOptimizedFontContent(family: string, preview?: string) {
    const googleFontUrlWithoutPreview = this.getFontUrl(family)

    let content = ''
    if (GoogleFontCache.verifyIfFontIsCached(googleFontUrlWithoutPreview)) {
      content = GoogleFontCache.fonts[googleFontUrlWithoutPreview]
    } else {
      const result = await fetch(googleFontUrlWithoutPreview)
      content = await result.text()

      GoogleFontCache.addFontToCache(googleFontUrlWithoutPreview, content)
    }

    const shouldUsePreview = content.length > 50000

    if (shouldUsePreview) {
      const googleFontUrl = this.getFontUrl(family, preview)

      if (GoogleFontCache.verifyIfFontIsCached(googleFontUrl)) {
        content = GoogleFontCache.fonts[googleFontUrl]
      } else {
        const result = await fetch(googleFontUrl)
        content = await result.text()

        GoogleFontCache.addFontToCache(googleFontUrl, content)
      }
    }

    return content
  }

  async loadFont(family: string, preview?: string) {
    if (!document.querySelector(`[href='https://fonts.gstatic.com/']`)) {
      const head = document.head || document.getElementsByTagName('head')[0]
      const link = document.createElement('link')
      link.href = 'https://fonts.gstatic.com/'
      link.rel = 'preconnect'
      head.appendChild(link)
    }

    const googleFontUrl = this.getFontUrl(family, preview)

    if (document.querySelector(`[href='${googleFontUrl}']`) != null) return

    const head = document.head || document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    link.href = googleFontUrl
    link.rel = 'stylesheet'
    head.appendChild(link)
  }

  async generateEncodedFontCss(font: string, preview?: string) {
    let content = await this.getOptimizedFontContent(font, preview)
    const fontLocations = content.match(/https:\/\/[^)]+/g) || []

    const fontLoadedPromises = fontLocations.map(async location => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        fetch(location)
          .then(result => result.blob())
          .then(blob => {
            reader.addEventListener('load', function () {
              content = content.replace(location, this.result as string)
              resolve([location, this.result])
            })

            reader.readAsDataURL(blob)
          })
          .catch(reject)
      })
    })

    const fontContent = await Promise.all(fontLoadedPromises).then(() => content)

    return fontContent
  }
}

export default new GoogleFontLoader()
